<template>
<div class="relative">
    <div class="vx-navbar-wrapper navbar-full p-0">
        <vs-navbar class="navbar-custom navbar-skelton" :class="navbarClasses" :style="navbarStyle" :color="navbarColor">

            <router-link tag="div" to="/" class="vx-logo cursor-pointer mx-auto flex items-center">
                <logo class="w-10 logo-center fill-current text-primary" />
            </router-link>

            <profile-drop-down />

        </vs-navbar>
    </div>
</div>
</template>

<script>
import SearchBar from './components/SearchBar.vue'
import NotificationDropDown from './components/NotificationDropDown.vue'
import ProfileDropDown from './components/ProfileDropDown.vue'
import Logo from '../Logo.vue'

export default {
    name: 'the-navbar-horizontal',
    props: {
        logo: {
            type: String
        },
        navbarType: {
            type: String,
            required: true
        },
        navColor: {
            type: String,
            default: '#fff'
        }
    },
    components: {
        Logo,
        SearchBar,
        NotificationDropDown,
        ProfileDropDown
    },
    computed: {
        navbarColor() {
            let color = '#fff'
            if (this.navbarType === 'sticky') color = this.navColor
            else if (this.navbarType === 'static') {
                if (this.scrollY < 50) {
                    color = this.navColor
                }
            }

            if (this.isThemedark === 'dark') {
                if (color === '#fff') {
                    color = '#10163a'
                } else {
                    color = '#262c49'
                }
            }

            return color
        },
        isThemedark() {
            return this.$store.state.theme
        },
        navbarStyle() {
            return this.navbarType === 'static' ? {
                transition: 'all .25s ease-in-out'
            } : {}
        },
        navbarClasses() {
            return this.scrollY > 5 && this.navbarType === 'static' ? null : 'd-theme-dark-light-bg shadow-none'
        },
        scrollY() {
            return this.$store.state.scrollY
        },
        verticalNavMenuWidth() {
            return this.$store.state.verticalNavMenuWidth
        },
        windowWidth() {
            return this.$store.state.windowWidth
        }
    }
}
</script>

<style scoped>
.w-18 {
    width: 18rem !important;
}

.w-10 {
    width: 10rem !important;
}

.logo-center {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}
</style>
