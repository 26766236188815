<template>

</template>

<script>
export default {
    props: {
        message: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: "dark",
        }
    },
    watch: {
        message: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                if (newValue !== "") {
                    this.$vs.notify({
                        title: "Notification",
                        text: newValue,
                        color: this.type,
                        position: "top-center"
                    });
                }
            }
        }
    },
}
</script>
