<template>
<div class="the-navbar__user-meta flex items-center">

    <div class="text-right leading-tight hidden sm:block">
        <small>Welcome</small>
        <p v-if="currentUser" class="font-semibold">{{ currentUser.firstname }} {{ currentUser.lastname }}</p>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">

        <div class="con-img ml-3">
            <img key="onlineImg" src="@/assets/images/logo/avatar-s-11.png" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
        </div>

        <vs-dropdown-menu class="vx-navbar-dropdown">
            <ul style="min-width: 9rem">

                <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="logout">
                    <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
                    <span class="ml-2">Logout</span>
                </li>
            </ul>
        </vs-dropdown-menu>
    </vs-dropdown>
</div>
</template>

<script>
import {
    profileData
} from "@/store/api/profile"

export default {
    data() {
        return {
            currentUser: {}
        }
    },
    created() {
        this.getUser();
    },
    methods: {
        async getUser() {
            this.currentUser = await profileData.SearchMyProfile();
        },
        logout() {
            this.$router.push("/logout");
        },
        editProfile() {
            this.$store.dispatch('editProfile');
        }
    }
}
</script>
