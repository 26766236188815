<template>
<div class="relative">
    <div class="vx-navbar-wrapper" :class="classObj">
        <vs-navbar class="vx-navbar navbar-custom navbar-skelton" :color="navbarColorLocal" :class="textColor">
            <feather-icon class="sm:inline-flex xl:hidden cursor-pointer p-2" icon="MenuIcon" @click.stop="showSidebar" />

            <div class="leading-tight text-primary hidden sm:block" v-if="selectedCompany === null">
                <p class="font-bold font-underline" @click="handleRegister">Please Register Your Vehicle</p>
            </div>

            <vs-spacer />
            <profile-drop-down />
        </vs-navbar>
        <div style="height:15px;">
            <vs-progress indeterminate color="primary" v-show="isProgressing"></vs-progress>
        </div>
    </div>
</div>
</template>

<script>
import ProfileDropDown from './components/ProfileDropDown.vue'

export default {
    name: 'the-navbar-vertical',
    data() {
        return {
            active: false,
            selectedCompany:{}
        }
    },
    props: {
        navbarColor: {
            type: String,
            default: '#fff'
        }
    },
    components: {
        ProfileDropDown,
    },
    computed: {
        navbarColorLocal() {
            return this.$store.state.theme === 'dark' && this.navbarColor === '#fff' ? '#10163a' : this.navbarColor
        },
        verticalNavMenuWidth() {
            return this.$store.state.verticalNavMenuWidth
        },
        textColor() {
            return {
                'text-white': (this.navbarColor !== '#10163a' && this.$store.state.theme === 'dark') || (this.navbarColor !== '#fff' && this.$store.state.theme !== 'dark')
            }
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },

        // NAVBAR STYLE
        classObj() {
            if (this.verticalNavMenuWidth === 'default') return 'navbar-default'
            else if (this.verticalNavMenuWidth === 'reduced') return 'navbar-reduced'
            else if (this.verticalNavMenuWidth) return 'navbar-full'
        },
        isProgressing() {
            return this.$store.state.isProgressing
        }
    },
    methods: {
        showSidebar() {
            this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
        },
        closeWindow() {
            this.active = false;
        },
        handleRegister() {
            this.$router.push("/RegisterCar");
        },
    }
}
</script>
